.App {
    font-family: sans-serif;
}
code[class*="language-"],
pre[class*="language-"] {
    color: #393A34;
    font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    font-size: .9em;
    line-height: 1.2em;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre>code[class*="language-"] {
    font-size: 1em;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
    background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
    background: #b3d4fc;
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border: 1px solid #dddddd;
    background-color: white;
}

/* Inline code */
:not(pre)>code[class*="language-"] {
    padding: .2em;
    padding-top: 1px;
    padding-bottom: 1px;
    background: #f8f8f8;
    border: 1px solid #dddddd;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #999988;
    font-style: italic;
}

.token.namespace {
    opacity: .7;
}

.token.string,
.token.attr-value {
    color: #e3116c;
}

.token.punctuation,
.token.operator {
    color: #393A34;
    /* no highlight */
}

.token.entity,
.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.property,
.token.regex,
.token.inserted {
    color: #36acaa;
}

.token.atrule,
.token.keyword,
.token.attr-name,
.language-autohotkey .token.selector {
    color: #00a4db;
}

.token.function,
.token.deleted,
.language-autohotkey .token.tag {
    color: #9a050f;
}

.token.tag,
.token.selector,
.language-autohotkey .token.keyword {
    color: #00009f;
}

.token.important,
.token.function,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}
/** ---------------------------- */
/** --- General ---------------- */
/** ---------------------------- */
textarea {
    border: 1px solid #999;
    width: 100%;
    height: 20em;
}

/** ---------------------------- */
/** --- Code editor ------------ */
/** ---------------------------- */
.code-edit-container {
    position: relative;
    height: 500px;
    border: 1px solid hsl(0, 0%, 60%);
    background-color: hsl(212, 35%, 95%);
    margin: 1em 0;
}

.code-input,
.code-output {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: none;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 0.8rem;
    background: transparent;
    white-space: pre-wrap;
    line-height: 1.5em;
    word-wrap: break-word;
    font-size: 1rem;
}

.code-input {
    opacity: 0;
    margin: 0;
    color: hsl(0, 0%, 40%);
    resize: none;
}

.code-output {
    pointer-events: none;
    z-index: 3;
    margin: 0;
    overflow-y: auto;
}

code {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 1rem;
    display: block;
    color: hsl(0, 0%, 40%);
    font-size: 0.8rem;
    font-family: "PT Mono", monospace;
}

.tag:not(body) {
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    display: initial;
    font-size: 1em;
    height: 2em;
    justify-content: center;
    line-height: inherit;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
}

.number {
    align-items: initial;
    background-color: initial;
    border-radius: initial;
    display: initial;
    font-size: 1rem;
    height: initial;
    justify-content: initial;
    margin-right: initial;
    min-width: initial;
    padding: initial;
    text-align: initial;
    vertical-align: inherit;
}

/* overrides */
.code-edit-container :not(pre)>code[class*="language-"],
.code-edit-container pre[class*="language-"] {
    background: transparent;
    margin: 0;
}
